import { ImageProductInterface } from "@app/_interfaces/image-product.interface";

export class ImageProduct{
    public img:string
    public imgZoom:string
    public imgThumbnail:string 
    public snPortada: boolean

    constructor(
        img:string,
        imgZoom:string,
        imgThumbnail:string,
        snPortada: boolean
    ){
        this.img = img
        this.imgZoom = imgZoom
        this.imgThumbnail = imgThumbnail
        this.snPortada = snPortada
    }

    public static Crear(obj:ImageProductInterface){
        return new ImageProduct(obj.url_standard,obj.url_zoom,obj.url_thumbnail,obj.is_thumbnail)
    }

}