import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { ProductListResponse } from "@app/_interfaces/product-list-bigCommerce.interface";
import { ImageProductInterface } from "@app/_interfaces/image-product.interface";
import { Observable, map } from "rxjs";
import { ImageProduct } from "@app/_model/image-product";
import { ProductDetailInterface } from "@app/_interfaces/product-detail.interface";

@Injectable()

export class ProductsService {
    private url: string
    urlMaterials: string
    urlCms:String

    constructor(private _http: HttpClient) {
        this.url = environment.apiUrl + '/productos'
        this.urlMaterials = environment.apiUrl;
        this.urlCms = environment.apiNews+'/products'
    }

    public getId(productId) {
        return this._http.get<ProductDetailInterface>(this.url + `/id/${productId}`)
    }

    public getFeatured() {
        return this._http.get(this.url + `/feature/1/p/1/l/3`)
        /*return this.dataStateService.checkAndGetData(
            OUR_FAVORITE_FIND_KEY,
            this._http.get(this.url + `/feature/1/p/1/l/3`),
            []
        )*/
    }

    public getFiles(productId){
        return this._http.get(this.urlCms +`?populate=products_file.file.fileUpload&filters[productId][$eq]=${productId}`)
    }

    public getOptions(productId) {
        return this._http.get(this.url + `/options/id/${productId}`)
    }

    public getImages(productId):Observable<ImageProduct[]> {
        return this._http.get<ImageProductInterface[]>(this.url + `/images/id/${productId}`).pipe(map(r=>r.map(image=>ImageProduct.Crear(image))))
    }

    public getVideos(productId) {
        return this._http.get(this.url + `/videos/id/${productId}`)
    }

    public getCustomFields(productId) {
        return this._http.get(this.url + `/custom-fields/id/${productId}`)
    }

    public getCatalogProducts(page, limit) {
        //availability=disabled&
        return this._http.get(this.url + `/p/${page}/l/${limit}?availability=disabled&include=primary_image`)
    }

    public getCatalogProductsFilter(page, limit, filters) {
        let queryParams = '?include=primary_image&availability=disabled'
        filters.forEach(filter => {
            queryParams += '&' + filter.filter + '=' + filter.value
        });
        //console.log(queryParams)
        return this._http.get(this.url + `/p/${page}/l/${limit}${queryParams}`)
        /*return this.dataStateService.checkAndGetData(this.dataStateService.getDynamicStateKey(`MORE_RECOMMENDED_KEY_${page}_${limit}_${queryParams}`),
            this._http.get(this.url + `/p/${page}/l/${limit}${queryParams}`),
            [])*/
    }

    public getTrendingProductsFilter(page, limit, filters) {
        let queryParams = '?include=primary_image'
        filters.forEach(filter => {
            queryParams += '&' + filter.filter + '=' + filter.value
        });
        //console.log(queryParams)
        return this._http.get(this.url + `/p/${page}/l/${limit}${queryParams}`)
    }

    public getOutletProducts(page, limit) {
        return this._http.get(this.url + `/p/${page}/l/${limit}?include=primary_image&availability=available&inventory_level:min=1`)
    }

    public getOutletProductsFilter(page, limit, filters?:Array<{filter:string,value:any}>) {
        let params = new HttpParams()
        .set('include','primary_image')
        .set('availability','available')
        .set('inventory_level:min','1')
        filters.forEach(filter=>{
            params = params.set(filter.filter,filter.value)
        })
        /*let queryParams = '?include=primary_image&availability=available&inventory_level:min=1'
        filters.forEach(filter => {
            queryParams += '&' + filter.filter + '=' + filter.value
        });*/
        return this._http.get(this.url + `/p/${page}/l/${limit}`,{params})
    }

    public searchProducts(search, page, limit) {
        let queryParams = `?keyword=${search}&include=primary_image`
        return this._http.get<ProductListResponse>(this.url + `/p/${page}/l/${limit}` + queryParams)
    }

    public getProductsCatalogByMaterial(material, page, limit, filters) {
        //console.log("Product service material catalog", material);
        let parsedMaterials: any = []
        material.forEach(element => parsedMaterials.push("material=" + encodeURI(element.name)))
        parsedMaterials = parsedMaterials.join("&");
        let queryParams = '&include=primary_image&availability=disabled'
        filters.forEach(filter => {
            queryParams += '&' + filter.filter + '=' + filter.value
        })
        //console.log("PETICION SERVICIO MATERIAL CATALOG: ", this.url + `/materials/p/${page}/l/${limit}?${parsedMaterials}` + queryParams);

        return this._http.get(this.url + `/materials/p/${page}/l/${limit}?${parsedMaterials}` + queryParams)
    }

    public getProductsOutletByMaterial(material, page, limit, filters) {
        //console.log("Product service material outlet", material);
        let parsedMaterials: any = []
        material.forEach(element => parsedMaterials.push("material=" + encodeURI(element.name)))
        parsedMaterials = parsedMaterials.join("&");
        let queryParams = '&include=primary_image&availability=available'
        filters.forEach(filter => {
            queryParams += '&' + filter.filter + '=' + filter.value
        })
        return this._http.get(this.url + `/materials/p/${page}/l/${limit}?${parsedMaterials}` + queryParams)
    }

    public getMaterials() {
        return this._http.get(this.urlMaterials + `/materials`)
    }
}